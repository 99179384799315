import React from 'react';
import StyledTwitter from './StyledTwitterCard';

const TwitterCard = () => (
  <StyledTwitter>
    <div className="Twitter-Card">
      <div className="Twitter-Card__Content">
        <div className="Twitter-Card__Title">
          <i className="icon-twitter" />
          <span className="Twitter-Card__Handler">@latimerhinks</span>
        </div>
        <div className="Twitter-Card__Tweet">
          <p>
            Follow us on twitter{' '}
            <a
              href="https://twitter.com/latimerhinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              @latimerhinks
            </a>
          </p>
        </div>
      </div>
    </div>
  </StyledTwitter>
);

export default TwitterCard;
