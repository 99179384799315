import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTwitter = styled.div`
  background-color: #00254a;
  height: 550px;
  position: relative;

  .Twitter-Card__Content {
    color: #ffffff;
  }

  .Twitter-Card__Title {
    font-size: 26px;
    font-family: 'henderson-sans-basic', sans-serif;
    letter-spacing: -1px;
    text-align: left;
    position: relative;
    top: 80px;
    left: 32px;
    width: calc(100% - 32px);

    @media screen and (min-width: 600px) {
      font-size: 28px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 24px;
    }
  }

  .Twitter-Card__Handler {
    position: relative;
    bottom: 8px;
    left: 8px;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      display: block;
      position: absolute;
      left: 0;
      top: 20px;
    }
  }

  .Twitter-Card__Tweet {
    font-size: 24px;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-25%);
    width: calc(100% - 64px);

    p {
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .icon-twitter {
    color: #f58021;
    font-size: 36px;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      display: block;
      position: absolute;
      right: 16px;
      bottom: 24px;
    }
  }
`;

const TwitterCardWrap = ({ children }) => (
  <StyledTwitter>{children}</StyledTwitter>
);

TwitterCardWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TwitterCardWrap;
