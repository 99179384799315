import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContact = styled.div`
  background-color: #00254a;
  height: 550px;
  font-family: 'henderson-sans-basic', sans-serif;
  position: relative;
  margin-bottom: 16px;

  .Contact-Card__Content {
    color: #ffffff;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(100% - 64px);
    left: 50%;
  }

  .Contact-Card__Title {
    font-size: 48px;
    line-height: 54px;
    width: 200px;
  }

  .Contact-Card__Body {
    padding-top: 16px;
    font-size: 26px;
    line-height: 28px;

    p {
      font-weight: 200;
      font-size: 20px;
    }
  }

  .Contact-Card__Icon {
    padding: 22px 32px;
    text-align: right;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 16px;
    }
  }

  .icon-chat {
    color: #f58021;
    font-size: 4rem;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 3rem;
    }
  }
`;

const ContactCardWrap = ({ children }) => (
  <StyledContact>{children}</StyledContact>
);

ContactCardWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContactCardWrap;
