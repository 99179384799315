import * as React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../../global/container/Container';
import ArticleCard from '../../common/article-card/ArticleCard';
import TwitterCard from '../../common/twitter-card/TwitterCard';
import ContactCard from '../../common/contact-card/ContactCard';

import { Cell, Grid } from '../../global/grid';

const PrintWrap = styled.div`
  @media print {
    display: none;
  }
`;

export const NewsFeed = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            date(formatString: "Do MMMM YYYY")
            slug
            articleAcf {
              featuredImage {
                sourceUrl
              }
            }
            categories {
              nodes {
                slug
                id
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = data.allWpPost;

  return (
    <PrintWrap>
      <Container verticalGap={16}>
        <Grid gutter={16}>
          {edges[0].node.articleAcf &&
          edges[0].node.articleAcf.featuredImage ? (
            <Cell cellSize={{ desktop: 6, widescreen: 6 }}>
              <ArticleCard
                date={edges[0].node.date}
                id={edges[0].node.id}
                title={edges[0].node.title}
                category={edges[0].node.categories.nodes[0].slug}
                image={
                  edges[0].node.articleAcf.featuredImage
                    ? edges[0].node.articleAcf.featuredImage.sourceUrl
                    : null
                }
                slug={edges[0].node.slug}
              />
            </Cell>
          ) : null}
          {edges[1].node.articleAcf &&
          edges[1].node.articleAcf.featuredImage ? (
            <Cell cellSize={{ desktop: 6, widescreen: 6 }}>
              <ArticleCard
                date={edges[1].node.date}
                id={edges[1].node.id}
                title={edges[1].node.title}
                category={edges[1].node.categories.nodes[0].slug}
                image={
                  edges[1].node.articleAcf.featuredImage
                    ? edges[1].node.articleAcf.featuredImage.sourceUrl
                    : null
                }
                slug={edges[1].node.slug}
              />
            </Cell>
          ) : null}
        </Grid>
        <Grid gutter={16}>
          <Cell
            cellSize={{ desktop: 3, widescreen: 3 }}
            cellOrder={{ phone: 2 }}
          >
            <ContactCard />
          </Cell>
          {edges[2].node.articleAcf &&
          edges[2].node.articleAcf.featuredImage ? (
            <Cell cellSize={{ desktop: 6, widescreen: 6 }}>
              <ArticleCard
                isCentral
                date={edges[2].node.date}
                id={edges[2].node.id}
                title={edges[2].node.title}
                category={edges[2].node.categories.nodes[0].slug}
                image={
                  edges[2].node.articleAcf.featuredImage
                    ? edges[2].node.articleAcf.featuredImage.sourceUrl
                    : null
                }
                slug={edges[2].node.slug}
              />
            </Cell>
          ) : null}
          <Cell
            cellSize={{ desktop: 3, widescreen: 3 }}
            cellOrder={{ phone: 3 }}
          >
            <TwitterCard />
          </Cell>
        </Grid>
      </Container>
    </PrintWrap>
  );
};
