import React from 'react';
import { Link } from 'gatsby';

import StyledContactCard from './StyledContactCard';

const ContactCard = () => (
  <Link to="/contact-us">
    <StyledContactCard>
      <div className="Contact-Card">
        <div className="Contact-Card__Icon">
          <i className="icon-chat" />
        </div>
        <div className="Contact-Card__Content">
          <div className="Contact-Card__Title">
            <p>Get in touch</p>
          </div>
          <div className="Contact-Card__Body">
            <p>Contact us to arrange a meeting with one of our friendly team members.</p>
          </div>
        </div>
      </div>
    </StyledContactCard>
  </Link>
);

export default ContactCard;
