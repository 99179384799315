import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';

const enlarge = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
`;

const StyledCarousel = styled.div`
  position: relative;
  margin-top: -44px;

  .slick-active {
    .CarouselSlide--Animated {
      @media screen and (min-width: 960px) {
        animation: ${enlarge} 15s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }

  .slick-dots {
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0.75);

    li {
      display: inline-block;
      margin-right: 12px;

      @media screen and (min-width: 768px) {
        margin-right: 16px;
      }
    }

    button {
      font-size: 0;
      background-color: rgba(255, 255, 255, 0.75);
      height: 16px;
      width: 16px;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      outline: none;

      @media screen and (min-width: 768px) {
        height: 20px;
        width: 20px;
      }
    }

    .slick-active button {
      background-color: #ffffff;
    }
  }
`;

const Carousel = ({ children, custom }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    lazyLoad: false,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 7000,
    ...custom,
  };

  const props = { ...settings };

  return (
    <StyledCarousel className="Carousel">
      <Slider {...props}>{children}</Slider>
    </StyledCarousel>
  );
};

Carousel.defaultProps = {
  fade: false,
  custom: {},
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  fade: PropTypes.bool,
  custom: PropTypes.shape({}),
};

export default Carousel;
