import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Carousel from '../carousel/Carousel';
import StyledTestimonial from './StyledTestimonial';

export const Testimonial = () => {
  const testimonials = useStaticQuery(graphql`
    {
      allWpTestimonial(limit: 4) {
        edges {
          node {
            id
            testimonialContent {
              clientTestimonial
            }
          }
        }
      }
    }
  `);

  const { edges } = testimonials.allWpTestimonial;

  return (
    <StyledTestimonial>
      <div className="Testimonial-Card">
        <Carousel>
          {edges.map((testimonial, key) => {
            const { node } = testimonial;

            return (
              <div key={node.id}>
                <h3 className="Testimonial-Card__Content">
                  &ldquo;{node.testimonialContent.clientTestimonial}&rdquo;
                </h3>
              </div>
            );
          })}
        </Carousel>
      </div>
    </StyledTestimonial>
  );
};
