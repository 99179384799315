import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
  padding: 32px;
  background-color: #f58021;
  position: relative;
  overflow: hidden;
  padding-top: 72px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: white;
  }

  @media screen and (min-width: 768px) {
    padding: 64px;
    padding-bottom: 38px;
  }

  .Testimonial-Card {
    .Carousel {
    }

    .slick-slider {
      height: 110%;
    }

    .slick-dots {
      margin: 0 auto;
      bottom: -8px;

      li:last-child {
        margin-right: 0px;
      }

      @media screen and (max-width: 768px) {
        position: absolute;
        transform: scale(0.6) translateX(-95%);
      }
    }
  }

  .Testimonial-Card::after {
    background: #ffffff;
    bottom: 0;
    content: '';
    display: block;
    height: 40%;
    left: 0;
    position: absolute;
    right: -10px;
    transform: skewY(-45deg);
    transform-origin: 100%;
    z-index: 1;

    @media screen and (min-width: 768px) {
      height: 50%;
    }
  }

  .Testimonial-Card__Content {
    positon: relative;
    color: #ffffff;
    text-align: center;
    max-width: 750px;
    font-size: 16px;
    font-weight: 300;
    margin: 0 auto;
    margin-bottom: 24px;

    @media screen and (min-width: 460px) {
      font-size: 18px;
    }
    @media screen and (min-width: 560px) {
      font-size: 20px;
    }

    @media screen and (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 32px;
      padding-top: 24px;
    }
  }
`;

const TestimonialWrap = ({ children }) => (
  <StyledTestimonial>{children}</StyledTestimonial>
);

TestimonialWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TestimonialWrap;
